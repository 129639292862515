@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: #ffffff;
}
@media only screen and (max-width: 800px) {
  .erti {
    width: 100%;
  }
  .rvas {
    margin-inline: 20px;
  }
  .titpath {
    height: 300px;
  }
  .backbath {
    height: 560px;
  }
  .inlipath {
    padding-inline: 10px;
    border-radius: 30px;
  }
}
@media only screen and (max-width: 1000px) {
  .iframe {
    width: 40%;
  }
  .ori {
    font-size: 14px;
    gap: 6px;
  }
  .ertias {
    font-size: 22px;
  }
  .ertiaso {
    width: 50px;
  }
  .sami {
    padding: 7px;
  }
  .videopath {
    width: 100%;
    padding-inline: 20px;
    top: 70%;
  }
  .titlepath {
    top: 20%;
  }
  .tripath {
    margin-top: 100px;
    margin-inline: 20px;
  }
}
@media only screen and (max-width: 1250px) {
  .tormeti {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    margin-inline: 10px;
  }
  .cameti {
    border-bottom-width: 2px;
  }
}
@media only screen and (max-width: 700px) {
  .overlay-content {
    display: grid;
    place-content: center;
  }
  .adres {
    margin-top: -55px;
  }
  .iframe {
    width: 90%;
    height: 300px;
    border-radius: 10px;
  }
  .images {
    min-height: 150px !important;
    max-height: 200px !important;
  }
  .videos {
    height: 150px !important;
  }
  .gridcontainer {
    margin-left: 5px;
    margin-right: 5px;
    gap: 5px;
  }
  .otxi {
    left: 10px;
  }
  .xuti{
    top: 30%;
  }
  .eqvsi {
    bottom: 20%;
  }
  .shvidi {
    bottom: 0%;
  }
  .cxra {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  .ati {
    padding-inline: 10px;
  }
  .totxmeti {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 400px) {
  .eqvsi {
    bottom: 30%;
  }
  .shvidi {
    bottom: 10%;
  }
  .rva {
    width: 300px;
  }
  .tormeti {
    gap: 10px;
    margin-inline: 0px;
  }
  .ati {
    gap: 5px;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  position: relative;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.overlay-media {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.imigecontain {
  width: 100%;
  height: 100%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 1000;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 36px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}
.Poppins {
  font-family: 'Poppins';
}
@font-face {
  font-family: 'Uni-sans';
  src: url('/src/components/assets/fonts/uni-sans.heavy-caps.otf'),
}
@font-face {
  font-family: 'Arial';
  src: url('/src/components/assets/fonts/bpg_arial_2009.ttf'),
}
@font-face {
  font-family: 'Mtavruli';
  src: url('/src/components/assets/fonts/mtavruli_bold.ttf'),
}
.Mtavruli {
  font-family: 'Mtavruli';
}
.Arial {
  font-family: 'Arial';
  font-weight: 500;
}
.topge {
  position: absolute;
  right: 0%;
  bottom: 0%;
}